/**
 * Reset some basic elements
 */
body, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, hr,
dl, dd, ol, ul, figure {
    margin: 0;
    padding: 0;
}

/** Variables **********************************************/
$base-font-family:              Helvetica, sans-serif;
$base-line-height:              1.5em;
$horizontal-spacing-unit:       10px;
$vertical-spacing-unit:         30px;
$nav-height:                    56px;
$small-font-size:               0.7em;
$base-font-size:                0.9em;

/* portfolio tinkering */
$img_spacing: 6px;
$img_height: 275px;
$caption_font_size: 0.3em;
$caption_color: #aaa;

/* COLORS  */
$red:               #FF3636;
$red-1:             #f95020 !default;
$orange:            #F29105;
$orange-1:          #ffcc22 !default;
$blue:              #2698BA;
$blue-1:            #0076df;
$blue-2:            #0000ff;
$dark-blue:         #00369f;
$green:             #11D68B;
$green-1:           #11D68B !default;
$green-2:           #00cf26 !default;
$dark-green:        #009f06 !default;
$light-green:       #ddffdd !default;
$lime-green:        #B7D12A;
$purple:            #B509AC;
$pink:              #f92080 !default;
$light-pink:        #ffdddd !default;
$yellow:            #efcc00 !default;

$grey-color-dark: #333;   /* footer */
$grey-color-light: #ddd;  /* navigation bar border */
$grey-color-lightest-code: #eee;  /* navigation bar border */
$text-color:     #666;

/* Set theme color *************************/
$theme-color:   $blue-1;

/************************************************************/

/**
 * Basic styling
 */
body {
    font-family: $base-font-family;
    font-size: $base-font-size;
    line-height: $base-line-height;
    font-weight: 100;
    color: $text-color;
    background-color: $background-color;
    -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
}



/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
p, blockquote, pre,
ul, ol, dl, figure,
%vertical-rhythm {
    margin-bottom: $vertical-spacing-unit / 2;
}

.alignleft {
    float: left;
}

.alignright {
    float: right;
}


hr{
    /* Inset, by Dan Eden */
    border: 0;
    height: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);

}


/**
 * Figures
 */
figure > img {
    display: block;
}

figcaption {
    font-size: $caption-font-size;
}



/**
 * Lists
 */
ul, ol {
    margin-left: $horizontal-spacing-unit;
}

li {
    > ul,
    > ol {
         margin-bottom: 0;
    }
}



/**
 * Headings
 */
h1, h2, h3, h4, h5, h6 {
    font-weight: 100;
}


/**
 * Links
 */
a {
    color: $text-color;
    text-decoration: none;
    /*
    &:visited {
        color: darken($brand-color, 15%);
    }
    */
    &:hover {
        color: $theme-color;
        text-decoration: none;
    }
}

article a {
    color: $theme-color;
    font-weight: 100;

    &:hover {
        text-decoration: none;
    }
}



/**
 * Blockquotes
 */
blockquote {
    color: $text-color;
    border-left: 10px solid $grey-color-light;
    padding-left: $horizontal-spacing-unit / 2;
    font-size: 1em;
    font-style: italic;

    > :last-child {
        margin-bottom: 0;
    }
}



/**
 * Code formatting
 */
pre,
code {
    font-size: 1.1em;
    border-radius: 3px;
    background-color: $grey-color-lightest-code;
}

code {
    padding: 1px 5px;
}

pre {
    padding: 8px 12px;
    overflow-x: scroll;

    > code {
        border: 0;
        padding-right: 0;
        padding-left: 0;
    }
}



/**
 * Wrapper
 */

.wrapper {
    max-width: -webkit-calc(800px - (#{$horizontal-spacing-unit} * 2));
    max-width:         calc(800px - (#{$horizontal-spacing-unit} * 2));
    margin-right: auto;
    margin-left: auto;
    padding-right: $horizontal-spacing-unit;
    padding-left: $horizontal-spacing-unit;
    @extend %clearfix;

    @include media-query($on-laptop) {
        max-width: -webkit-calc(800px - (#{$horizontal-spacing-unit}));
        max-width:         calc(800px - (#{$horizontal-spacing-unit}));
        padding-right: $spacing-unit / 2;
        padding-left: $spacing-unit / 2;
    }
}


/**
 * Clearfix
 */
%clearfix {
    &:before,
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}
