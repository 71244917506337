.home_page {
  min-height: 320px;
  position: relative;
  background-image: linear-gradient(0deg, #FDFDFD, transparentize($theme-color, 0.8));
  border: 3px solid;
  border-bottom: none;
  text-align: center;
  border-image: linear-gradient(0deg, #FDFDFD, transparentize($theme-color, 0.6)) 10;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  &:hover {
    background-image: linear-gradient(0deg, #FDFDFD, transparentize($theme-color, 0.8));
    img {
      padding-top: 48px;
      height: 252px;
    }
    h2.home_message {
            font-size: 2.01em;
    }
  }
  h2 {
    margin-top: 20px;
    &.home_message {
      font-size: 2em;
      font-weight: 200;
      text-align: center;
      opacity: 0.75;
      color: $theme-color;
    }
  }
  img {
    position: relative;
    padding-top: 50px;
    height: 250px;
  }
}
/**
 * Site header   **********************************************************
 */
.site-header {
  border-top: 7px solid $theme-color;
  border-bottom: 1px solid $grey-color-light;
  background-color: #fff;
  opacity: 0.9;
  position:fixed;
  left:0px;
  top:0px;
  height:56px;
  width:100%;
  z-index: 50;
}

.site-title {
  font-size: 1.4em;
  line-height: $nav-height;
  letter-spacing: -1px;
  margin-bottom: 0;
  &:hover {
    text-decoration: none;
    color: $theme-color;
  }
}

.site-logo {
  float: left;
  line-height: $nav-height - 10;
  max-height: $nav-height - 10;
  margin: 1px 10px 1px 1px;
  &:hover {
    border-style: solid;
    border-width: 1px;
    margin: 0 9px 0 0;
  }
}

.site-nav {
  float: right;
  line-height: $nav-height;
  .page-link{
    line-height: $base-line-height;
    // Gaps between nav items, but not on the first one
    &:not(:first-child) {
        margin-left: 10px;
    }
  }
  @include media-query($on-palm) {
    position: fixed;
    top: 0px;
    right: 10px;
    text-align: right;
    &:hover .trigger {
      display: block;
      padding-bottom: 5px;
    }
    .page-link {
      display: line;
    }
  }
}

.header-bar{
  font-size: 1.4em;
  display: block;
  opacity: 0.75;
  width: 100%;
  text-align: center;
  padding-bottom: 50px;
  margin-bottom: 80px;
  line-height: 2em;
  z-index: 15;
  border-bottom: 1px solid $grey-color-light;
  h1 {
    color: $theme-color;
    font-size:2.5em;
  }
  h2 {
    font-size:1.2em;
  }
}

/**
 * Site footer  **********************************************************
 */
.site-footer {
  border-top: 1px solid $grey-color-dark;
  font-size: 0.7em;
  background-color: $grey-color-dark;
  color: #aaa;
  height:2em;
  width:100%;
  position: fixed;
  left: 0px;
  bottom: 0px;
  a {
    color: #fff;
  }
  a:hover {
    color: $theme-color;
  }
}

/**
 * Page content   **********************************************************
 */
.page-content {
  padding-top: 90px; /* VERTICAL PADDING FOR TITLE ON EVERY PAGE */
}

.page-heading {
  font-size: 1.4em;
}

.portfolio-list {
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  ul {
    margin: 0;
  }
  li {
    float:left;
    border-top: 1px solid $grey-color-light;
    white-space: nowrap;
    list-style-type: none;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  h2.portfolio {
    font-size: 1.6em;
    color: $theme-color;
  }
  p {
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 1.2em;
    &.portfolio-meta {
      font-size: 0.9em;
    }
  }
  img {
    width: 100%;
    height: auto;
    position: relative;
    top: -50%;
    @include media-query($on-palm) {
      width: 200%;
      top: 0%;
    }
  }
  a {
    float:left;
    position: relative;
    width: 100%;
    height: auto;
    color: $text-color;
      span {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: linear-gradient(0deg, rgba(254,254,254,0.1), rgba(10,10,10,0.9));
        color: $grey-color-light;
        padding: 20px;
      }
    :hover {
      span {
        background: linear-gradient(0deg, transparentize(#FEFEFE, 0.6), rgba(10,10,10,0.9));
      }
    }
  }
}

.post-list {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-left: 0;
  margin-right: 0;
  text-align: center;
  ul {
    overflow: hidden;
  }
  li {
    white-space: nowrap;
    display: inline;
    list-style-type: none;
    margin-left: 2%;
    margin-right: 2%;
  }
  a {
    font-size: 1em;
    color: $text-color;
    &.favorite {
      font-weight:600;
    }
    &:hover {
      color: $theme-color;
    }
  }
}

.archive-list {
  .archive-row {
    padding: $img_spacing;
  }
  .archive-year {
    margin-top: 30px;
  }
  ul{
    border-top: 1px solid $grey-color-light;
    padding-bottom: 20px;
    margin:0;
  }
  li {
    margin-left: -10px;
    margin-right: -10px;
    white-space: nowrap;
    list-style-type: none;
  }
  h3 {
    line-height: $base-line-height;
    margin: 0;
  }
  p.archive-meta {
    margin: 0;
    text-align: right;
    line-height: $base-line-height + 0.3;
    @include media-query($on-palm) {
      width: 200%;
      top: 0%;
    }
  }
  a {
    &.favorite {
      font-weight:600;
    }
    &:hover {
      color: darken($theme-color, 20%);
    }
  }
}

.contacticon {
  border-top: 1px solid $grey-color-light;
  max-width: 30%;
  margin: 32px auto auto auto;
  padding-bottom: 80px;
  padding-top: 20px;
  font-size: 2.9em;
  display:block;
  p {
    font-size: 0.3em;
  }
}

.center{
  text-align: center;
}

/**
 * Posts  **********************************************************
 */
.post-header {
  margin-bottom: $vertical-spacing-unit;
}

.post-title {
  font-size: 2.2em;
  letter-spacing: -1px;
  line-height: 1;
  @include media-query($on-laptop) {
    font-size: 1.9em;
  }
}

.post-content {
  h1 {
    font-size: 2.0em;
    font-weight: 500;
    padding-bottom: 15px;
    @include media-query($on-laptop) {
      font-size: 1em;
    }
  }
  h2 {
    font-size: 1.6em;
    font-weight: 500;
    padding-bottom: 15px;
    @include media-query($on-laptop) {
      font-size: 1em;
    }
  }
  h3 {
    font-size: 1.4em;
    font-weight: 400;
    padding-bottom: 12px;
    @include media-query($on-laptop) {
      font-size: 0.9em;
    }
  }
  h4 {
    font-size: 1.2em;
    font-weight: 300;
    padding-bottom: 10px;
    @include media-query($on-laptop) {
      font-size: 0.8em;
    }
  }
  h5 {
    font-size: 1em;
    font-weight: 200;
    padding-bottom: 10px;
    @include media-query($on-laptop) {
      font-size: 0.5em;
    }
  }
  p {
    font-size: 1em;
    text-align: justify;
  }
}

.post-meta {
  font-size: $small-font-size;
  color: $grey-color;
  margin-bottom: 0px;
}

.post-link {
  display: block;
}

/* profile image settings */
.profile-image {
   margin: auto auto auto 10px;
}

/**
 * Portfolio grid **********************************************************
*/
// Nicolas Gallagher's micro clearfix hack
// http://nicolasgallagher.com/micro-clearfix-hack/
.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

.thumbnail{
  width: 100%;
  height: 150px;
  overflow: hidden;
}

.thumbnail-portfolio {
  width: 100%;
  height: 300px;
  overflow: hidden;
}

.series-list {
  h2 {
    color: $theme-color;
    font-weight: 100;
    font-size: 1.3em;
  }
}

.category-list {
  width: 100%;
  text-align: center;
  padding-bottom: 5px;
  h1 {
    font-size:2.5em;
  }
  h5 {
    font-size: 0.9em;
    padding-bottom: 5px;
  }
  ul {
    overflow: hidden;
    margin-left: 0;
    margin-right: 0;
  }
  li {
    text-align: center;
    white-space: nowrap;
    display: inline;
    list-style-type: none;
  }
  a {
    font-size: 1.2em;
    margin: auto 5px auto 5px;
    color: $text-color;
    &.current {
      font-weight: 500;
      opacity: 0.75;
      font-size: 1.5em;
      color: $theme-color;
      text-decoration: underline;
      text-decoration-color: $text-color;
    }
    &.home-page {
      font-size: 1.5em;
      opacity: 0.75;
      color: $theme-color;
      font-weight: 500;
      text-decoration: underline;
      text-decoration-color: $text-color;
    }
    &:hover {
      color: $theme-color;
    }
  }
}

.category {
  width: 33.33%;
  height: 200px;
  float: left;
  vertical-align: middle;
  box-sizing: border-box;
  padding: 10px;
  h4 {
    text-align: center;
    margin: 0.5em auto 0.75em auto;
  }
  p {
    text-align: center;
  }
  img {
    width: 320px;
    height: auto;
    position: relative;
    left: -25%;
    top: -30%;
  }
  a {
    float: left;
    position: relative;
    width: 100%;
    height: 150px;
      span {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: rgba(0,0,0,0.4);
        color: $grey-color-light;
        padding: 20px;
      }
    :hover {
      span {
        display: block;
      }
    }
  }
}

/**
 * Portfolio pages **********************************************************
*/
.blankbox{
  background: $theme-color;
}

.img_row{
  height: 100%;
  width: 100%;
  overflow: hidden;
  box-sizing:border-box;
  padding: $img_spacing;
}

.col{
  width: 100%;
  height: 100%;
  float: left;
  object-fit: cover;
  box-sizing:border-box;
  padding: $img_spacing;
}

.right{
  float: right;
}

.one {
  width:33.33%;
}

.two {
  width: 66.66%;
}

.three{
  width: 100%;
}

.caption{
  height: 100%;
  color: $caption_color;
  text-align: center;
  vertical-align: top;
  font-size: $base_font_size;
}
